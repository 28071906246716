import React, { useState } from "react";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = ({setIsLoggedIn}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_id: "",
    pwd: "",
  });

  const [errors, setErrors] = useState({
    user_id: "",
    pwd: "",
  });

  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const handleValidation = (name, value) => {
    let errmsg = "";
    const trimmedValue = value && typeof value === "string" ? value.trim() : value;
    switch (name) {
      case "user_id":
        if (!trimmedValue) {
          errmsg = "Please Enter Name.";
        }
        break;
      case "pwd":
        if (!trimmedValue) {
          errmsg = "Please Enter Name.";
        }
        break;
      default:
        break;
    }
    return errmsg;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const error = handleValidation(name, value);
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: error });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    axios.post(`https://auh-expo-server.xicsolutions.in/login/login`, formData)
      .then((res) => {
        console.log("Data", res.data);
        sessionStorage.setItem("login", "true");
        sessionStorage.setItem("admin_id", res.data.admin_id);
        sessionStorage.setItem("admin_name", res.data.admin_name);
        sessionStorage.setItem("Role", res.data.admin_role);
        toast.success("Login successfully.", { autoClose: 1000 });
        
        setTimeout(() => {
          window.location.href = "/adminIndex";
        }, 1000);
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message || "Failed to login";
        console.log("Error:", errorMsg);
        toast.error(errorMsg, { autoClose: 3000 });
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); 
  };

  return (
    <div className="login-secttion">
      <ToastContainer position="top-right" style={{ zIndex: 9999 }} />
      <div className="login-container">
        <div className="d-flex justify-content-center mt-5">
          <div className="login-box  ">
            <div className="login-input-group">
              <h2 className="login-title text-center text-white mt-2">ADMIN LOGIN</h2>
              <label className="login-label">User Name</label>
              <input
                type="text"
                name="user_id"
                onChange={handleInputChange}
                required
                className="login-name"
              />
            </div>
            <div className="login-input-group">
              <label className="login-label">Password</label>
              <input
                type={showPassword ? "text" : "password"} 
                name="pwd"
                onChange={handleInputChange}
                className="login-pwd"
                required
              />
              <span
                className="login-toggle-password"
                onClick={togglePasswordVisibility} 
                style={{ cursor: "pointer" }}
              >
                {showPassword ? "🙈" : "👁️"} 
              </span>
            </div>
            <button
              type="submit"
              onClick={handleLogin}
              className="login-button"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
