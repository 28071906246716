import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./RegisterationFooter.css";
import kamaLogo from "../Images/logo.png";
import brokers_view from "../Images/Logos/brokersview-icon.png";

function RegisterationFooter() {
    return (
        <div className="reg-footer mt-5">
            
                <Row className="reg-footer-content">
                    <Col xs={12} md={6} sm={6} lg={6} className="footer-item d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-center mb-3 mb-md-0">
                        <img src={kamaLogo} alt="Kama Capital Logo" className="kama-footer-logo" />
                    <a href="https://kama-capital.com/" target="_blank" rel="noopener noreferrer">
                        www.kama-capital.com
                        </a>
                    </Col>
                    <Col xs={12} md={6} sm={6} lg={6} className="footer-item d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-center  mb-3 mb-md-0">
                        <img src={brokers_view} alt="IFX Expo Logo" className="brokerview-footer-logo" />
                    <a href="https://www.brokersview.com/" target="_blank" rel="noopener noreferrer">
                            www.brokersview.com
                        </a>
                    </Col>
                </Row>
            
        </div>
    );
}

export default RegisterationFooter;
