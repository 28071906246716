import React, { useEffect, useRef, useState } from 'react'
import './LeaderBoard.css'
import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import leader_board_vdo from '../Images/leader-board/new-leader-board-vdo.mp4'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeaderBoard = () => {
    const [leaderData, setLeaderData] = useState([]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get('https://auh-expo-server.xicsolutions.in/score/leaderboarddata');


      const filteredData = response.data.filter(user => user.score >= 3);
      
      // Sort based on score first, then by unique_id (ascending) to get earlier registered users first
      const sortedData = filteredData
        .sort((a, b) => {
          if (b.score === a.score) {
            // If scores are the same, return the earlier registered user by unique_id
            return a.unique_id - b.unique_id; // Compare by numeric value if unique_id is numeric
          }
          return b.score - a.score; // Sort by score descending
        });
      
      setLeaderData(sortedData);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message, { autoClose: 3000 });
      } 
    }
  };

  useEffect(() => {
    // Fetch the leaderboard data initially
    fetchLeaderboardData();

    // Set up polling every 10 seconds
    const intervalId = setInterval(() => {
      fetchLeaderboardData();
    }, 10000); // Fetches new data every 10 seconds

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const top5 = leaderData.slice(0, 5);



const vdoRef = useRef();

useEffect(()=>{
if(vdoRef.current){
  vdoRef.current.play()
}
},[])
  return (
    <div className='leader-board-section'>
      <Container >
        <Row className='d-flex align-items-stretch leader-board-row-height'>
            <Col xs={12} md={6} className="d-flex leader-board-col-height">
              <TableContainer className='leader-board-container flex-grow-1'>
                <Table className='leader-board-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell className='leader-board-table-head'>RANK</TableCell>
                      <TableCell className='leader-board-table-head'>IMAGE</TableCell>
                      <TableCell className='leader-board-table-head'>NAME</TableCell>
                      <TableCell className='leader-board-table-head'>SCORE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {top5.map((data, index) => (
                      <TableRow key={index} sx={{'&:last-child td, &:last-child th': { borderBottom: 0 } }}>
                        <TableCell className='leader-board-table-data'>{index + 1}</TableCell>
                        <TableCell className='leader-board-table-data'>
                          <img
                            src={`https://auh-expo-server.xicsolutions.in/uploads/${data.user_img}`}
                            alt={data.user_name}
                            className='leader-board-user'
                          />
                        </TableCell>
                        <TableCell className='leader-board-table-data'>{data.user_name}</TableCell>
                        
                        <TableCell className='leader-board-table-data'>{data.score}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
            <Col xs={12} md={6} className="d-flex leader-board-col-height">
              <video ref={vdoRef} src={leader_board_vdo} className='leader-board-video flex-grow-1' controls autoplay muted loop/>
            </Col>
        </Row>
      </Container>
      <ToastContainer />
      
    </div>
  )
}

export default LeaderBoard;
