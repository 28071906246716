import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  DialogTitle,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaEdit, FaSearch } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import "./AddScore.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import grabow from '../Images/GRABOW-CHALLENGE-NEW.png'
import AddScoreForm from "./AddScoreForm/AddScoreForm";




const AddScore = () => {
  const navigate = useNavigate();
  const [regData, setRegData] = useState([]);

  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [openDlt, setOpenDlt] = useState(false);
  const [dltData, setDltData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [openScore, setOpenScore] = useState(false); // State to manage score dialog visibility
  const [scoreData, setScoreData] = useState();

  const [dataPerPage, setDataPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axios
      .get(`https://auh-expo-server.xicsolutions.in/user/getUsersData`)
      .then((res) => {
        setRegData(res.data);
        setFilteredDatas(res.data);
      })
      .catch((err) => {
        console.log("Registration Data is not fetched.", err);
      });
  }, [openUpdate, openDlt]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = regData.filter(
      (item) =>
        item.user_name.toLowerCase().includes(lowercasedQuery) ||
        item.email.toLowerCase().includes(lowercasedQuery) ||
        item.country.toLowerCase().includes(lowercasedQuery) ||
        item.unique_id.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredDatas(filtered);
  }, [searchQuery, regData]);

  const handleAddScore = (user_id) => {
    const selectData = regData.find(user => user.user_id == user_id)
    if(selectData){
      setOpenScore(true); // Open the score dialog
      setScoreData({ ...scoreData, user_id: user_id }); // Set the registration number in the score data
    }
  };



  const handleScoreSubmit = () => {
    axios
      .post(
        `https://auh-expo-server.xicsolutions.in/score/storeUserScore`,
        scoreData
      )
      .then((res) => {
        toast.success("Successfully added score",{autoClose:3000})
        setTimeout(()=>{
            navigate("/adminIndex");
        },3000)
      })
      .catch((err) => {

        if(err.response){
            toast.error(err.response.data.message,{autoClose:3000})
        }
        console.log("Error failed to add score", err);
        
      });
  };

  const handleChangeDataPerPage = (e) => {
    const newDataPerPage = e.target.value === regData.length ? regData.length : parseInt(e.target.value, 10);
    setDataPerPage(newDataPerPage);
    setCurrentPage(1);
};

const firstIndexOfData = (currentPage - 1) * dataPerPage;
const lastIndexOfData = currentPage * dataPerPage;
const currentData = filteredDatas.slice(firstIndexOfData, lastIndexOfData);

  return (
    <div className="registration-index-section">
        <ToastContainer 
  position="top-right" 
  // style={{ zIndex: 9999 }} 
/>
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            {/* <h1 className="text-center" style={{ color: 'red',fontWeight:'bold',fontFamily:'Gellix' }}>GRABOW CHALLENGE</h1> */}
            <img src={grabow} className="grabow-img"/>
          </Col>
          <Col xs={12} >
            <h1 className="text-center mt-2" style={{ color: 'white',fontWeight:'bold',fontFamily:'Gellix' }}>UPDATE SCORE</h1>
          </Col>
          
        </Row>

        <Row className="mt-5 mb-5">
                    <Col xs={12} md={3} lg={3} className="d-flex justify-content-center justify-content-lg-start justify-content-md-start mt-md-0 mt-3">
                    
                        <Button  onClick={() => navigate('/adminIndex')} className="navigate-btns ">
                            Home
                        </Button>
                    </Col>
                    
                        <Col xs={12} md={6} lg={6} className="d-flex justify-content-lg-center justify-content-md-center justify-content-center  mt-md-0 mt-3">
                    <div className="search-container">
                    <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    aria-label="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <FaSearch className="searchicon" />
                            </div>
                            </div>
                        </Col> 

                        <Col xs={12} md={3} lg={3} className="d-flex justify-content-center justify-content-lg-end justify-content-md-start mt-md-0 mt-3">
                    
                        <Select
                className="pagination-btn"
                value={dataPerPage}
                onChange={handleChangeDataPerPage}
              >
                <MenuItem value={20}>20 Per Page</MenuItem>
                <MenuItem value={30}>30 Per Page</MenuItem>
                <MenuItem value={50}>50 Per Page</MenuItem>
                <MenuItem value={regData.length}>All Per Page</MenuItem>
              </Select>
                    </Col>
                </Row> 
        <TableContainer className="table-container">
          <Table>
            <TableHead>
              <TableRow >
                <TableCell
                  style={{ backgroundColor: "#6000d9", color: "white",fontFamily:'Gellix',fontWeight:'bold',fontSize:'1em' }}
                >
                  S.NO
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#6000d9", color: "white",fontFamily:'Gellix',fontWeight:'bold',fontSize:'1em' }}
                >
                  PHOTO
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#6000d9", color: "white",fontFamily:'Gellix',fontWeight:'bold',fontSize:'1em' }}
                >
                  UNIQUE ID
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#6000d9", color: "white",fontFamily:'Gellix',fontWeight:'bold',fontSize:'1em' }}
                >
                  NAME
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#6000d9", color: "white",fontFamily:'Gellix',fontWeight:'bold',fontSize:'1em' }}
                >
                  MOBILE NO
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#6000d9", color: "white",fontFamily:'Gellix',fontWeight:'bold',fontSize:'1em' }}
                >
                  UPDATE SCORE
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((reg, index) => (
                <>
                  <TableRow key={reg.user_id}  className="table-row" sx={{'&:last-child td, &:last-child th' : {borderBottom:0}}}>
                    <TableCell className="table-cell-value">{index + 1}</TableCell>
                    <TableCell>
                      <img
                        src={`https://auh-expo-server.xicsolutions.in/uploads/${reg.user_img}`}
                        alt={reg.user_name}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="table-cell-value">{reg.unique_id}</TableCell>
                    <TableCell className="table-cell-value">{reg.user_name}</TableCell>
                    <TableCell className="table-cell-value">{reg.phone_no}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleAddScore(reg.user_id)} 
                        style={{ color: "white" }}
                      >Score</Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Row className="mt-3">
                <Col xs={12} className="d-flex justify-content-center">
                <Stack spacing={2}>
                        <Pagination 
                        count={Math.ceil(filteredDatas.length / dataPerPage)}
                        page={currentPage}
                        onChange={(e, value) => setCurrentPage(value)}
                        sx={{
                          '& .MuiPaginationItem-root': {
                            color: 'white',
                            backgroundColor: '#6000d9',
                          }
                        }}
                         />
                        </Stack>
                </Col>
                </Row>
      </Container>


      {openScore &&(
        <AddScoreForm data={scoreData} open={openScore} onClose={() => setOpenScore(false)}/>
      )}


    </div>
  );
};

export default AddScore;
