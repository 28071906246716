import React, { useEffect, useRef, useState } from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import LeaderboardClock from './Clock/LeaderboardClock';
import LeaderBoard from './LeaderBoard';
import Marquee from "react-fast-marquee";
import kamaIcon from '../Images/logo.png';
import Confetti from './Confetti';
import './LeaderBoardRouting.css'
import moment from 'moment';

const LeaderBoardRouting = () => {
  const [hideClock, setHideClock] = useState(false);

  const handleClock = () => {
    const curtTime = moment();  // Current time
    const startTime = moment().set({ hour: '18', minute: '30', second: '0' });  // Target time to hide the clock
    const endTime = moment().set({ hour: '18', minute: '31', second: '0' });  // Time to reset and show the clock again

    // Hide the clock when the current time matches the start time
    if (curtTime.isSameOrAfter(startTime, 'second') && curtTime.isBefore(endTime, 'second')) {
      setHideClock(true);
    } else if (curtTime.isSameOrAfter(endTime, 'second')) {
      // Reset clock after the end time
      setHideClock(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleClock();
      
    }, 1000); 

    
    return () => clearInterval(interval);
  }, []);

    
  return (
    <div style={{overflow:'hidden'}}>
      <NavigationBar />
     {hideClock ? (
       <div className='leader-board-confetti-section'>
       <Confetti/>
     </div>
     ):(
      <div className='mt-4'>
      <LeaderboardClock />
    </div>
     )}

     
      
      <div className='mt-4'>
        <LeaderBoard />
      </div>
      <div className='leader-board-footer-container'>
        <Marquee pauseOnHover={true} speed={60} gradient={false}>
          <div className='marquee-item'>
            <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon" />
            <h1 className='leader-board-footer-text mt-2'>Collaborate For Global Market Excellence</h1>
          </div>
          <div className='marquee-item'>
            <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon" />
            <h1 className='leader-board-footer-text mt-2'>Navigating Markets With Precision</h1>
          </div>
          <div className='marquee-item'>
            <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon" />
            <h1 className='leader-board-footer-text mt-2'>Access Global Markets With an Adaptive Fintech Institution</h1>
          </div>
          <div className='marquee-item'>
            <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon" />
            <h1 className='leader-board-footer-text mt-2'>www.kama-capital.com</h1>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default LeaderBoardRouting;
