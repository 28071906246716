import React from "react";
import ifx_image_one from "../Images/ifx_images/g-1.jpg";
import ifx_image_two from "../Images/ifx_images/g-2.jpg";
import ifx_image_three from "../Images/ifx_images/g-3.jpg";
import ifx_image_four from "../Images/ifx_images/g-4.jpg";
import ifx_image_five from "../Images/ifx_images/g-5.jpg";
import ifx_image_six from "../Images/ifx_images/g-6.jpg";
import ifx_image_seven from "../Images/ifx_images/g-7.jpg";
import "./Gallary.css";

function Gallary() {
  return (
    <div className="ifx_images_grid">
      <div className="ifx-image-one">
        <img src={ifx_image_one} alt="Image One"  />
      </div>
      <div className="ifx-image-two">
        <img src={ifx_image_two} alt="Image Two" />
      </div>
      <div className="ifx-image-three">
        <img src={ifx_image_three} alt="Image Three" />
      </div>
      <div className="ifx-image-four">
        <img src={ifx_image_four} alt="Image Four" />
      </div>
      <div className="ifx-image-five">
        <img src={ifx_image_five} alt="Image Five" />
      </div>
      <div className="ifx-image-six">
        <img src={ifx_image_six} alt="Image Six" />
      </div>
      <div className="ifx-image-seven">
        <img src={ifx_image_seven} alt="Image Seven" />
      </div>
    </div>
  );
}

export default Gallary;
