import React, { useCallback, useRef, useState, useEffect } from "react";
import { FaCamera, FaUpload } from "react-icons/fa";
import axios from "axios";
import countryData from "../../../country.json";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import "./UpdateRegistrationForm.css";
import { Container } from "react-bootstrap";
import moment from "moment";

function UpdateRegistrationForm({ userData, handleUpdateSuccess }) {
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [formData, setFormData] = useState({
    user_name: userData ? userData.user_name : "",
    user_phone: userData ? userData.phone_no : "",
    countrycode: userData ? userData.country_code : "",
    user_email: userData ? userData.email : "",
    country: userData ? userData.country : "",
    user_image: userData ? userData.user_img : "",
    updated_at: moment().format("YYYY-MM-DD HH:mm:ss")
  });
  const [fileSelected, setFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileSelected(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (formData.country) {
      const countryName = countryData.find((c) => c.name === formData.country);
      setFormData((prev) => ({
        ...prev,
        countrycode: countryName ? countryName.dial_code : "",
      }));
    } else {
      setFormData((prev) => ({ ...prev, countrycode: "" }));
    }
  }, [formData.country]);
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    const data = new FormData();
    data.append("user_name", formData.user_name);
    data.append("user_phone", formData.user_phone);
    data.append("countrycode", formData.countrycode);
    data.append("user_email", formData.user_email);
    data.append("country", formData.country);

    if (fileSelected) {
      data.append("user_image", fileSelected);
    }

    try {
      await axios.put(
        `https://auh-expo-server.xicsolutions.in/user/updateUserData/${userData.user_id}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      handleUpdateSuccess();
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error updating user");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
        setSuccess(false);
        setError(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <Container>
      <div className="row justify-content-center p-5 ">
        <div className="card  shadow admin-edit-reg-index-con col-lg-12 col-md-10 col-sm-12 profile-container p-4 m-5">
          <h3 className="card-title text-center text-white mt-4">
            Update Registration
          </h3>
          <div className="card-body">
            {message && (
              <Typography sx={{ marginBottom: "1rem" }}>
                <Alert severity={success ? "success" : "error"}>
                  {message}
                </Alert>
              </Typography>
            )}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="user_name" className="admin-edit-form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  required
                  value={formData.user_name}
                  onChange={(e) =>
                    setFormData({ ...formData, user_name: e.target.value })
                  }
                />
              </div>

              <div className="mb-3">
                <label htmlFor="user_email" className="admin-edit-form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  required
                  value={formData.user_email}
                  onChange={(e) =>
                    setFormData({ ...formData, user_email: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="country" className="admin-edit-form-label">
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={(e) =>
                    setFormData({ ...formData, country: e.target.value })
                  }
                >
                  <option value="">Please select country</option>
                  {countryData.map((country) => (
                    <option key={country.name} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="user_phone" className="admin-edit-form-label">
                  Phone Number
                </label>
                <div className="d-flex">
                  <select
                    id="country_code"
                    name="country_code"
                    className="w-25 me-2"
                    required
                    value={formData.countrycode}
                    disabled
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        countrycode: e.target.value,
                      })
                    }
                  >
                    {countryData.map((code) => (
                      <option key={code.dial_code} value={code.dial_code}>
                        {code.dial_code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    id="user_phone"
                    name="user_phone"
                    required
                    value={formData.user_phone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        user_phone: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <button
                type="submit"
                className="admin-edit-registeration-button mt-5"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default UpdateRegistrationForm;
